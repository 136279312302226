const dashboard = [
  {
    path: '/dashboards/general',
    name: 'dashboard-general',
    component: () => import('@/views/dashboards/general/General.vue'),
    meta: {
      layout: 'content',
      resource: 'dashboard',
      action: 'view',
    },
  },
]

export default dashboard
