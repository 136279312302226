import { mdiStorefrontOutline } from '@mdi/js'

export default [
  {
    subheader: 'ONBOARDING',
    resource: 'menu_sh_onboarding',
    action: 'view',
  },
  {
    title: 'Shops',
    icon: mdiStorefrontOutline,
    to: 'shops-list',
    resource: 'shops',
    action: 'list',
  },
]
