const onboarding = [
  {
    path: '/shops/list',
    name: 'shops-list',
    component: () => import('@/views/onboarding/shops/shop-list/ShopList.vue'),
    meta: {
      layout: 'content',
      resource: 'shops',
      action: 'list',
    },
  },
  {
    path: '/shops/edit/:id/',
    name: 'shop-edit',
    component: () => import('@/views/onboarding/shops/shop-list/shop-edit/Shop.vue'),
    meta: {
      layout: 'content',
    },
    query: null,
  },
]

export default onboarding
