import { mdiCreditCardMultipleOutline, mdiBankTransferIn, mdiBankTransfer, mdiArchiveOutline, mdiPiggyBankOutline } from '@mdi/js';

export default [
  {
    subheader: 'PROCESSING',
    resource: 'menu_sh_processing',
    action: 'view',
  },
  {
    title: 'Payments',
    icon: mdiPiggyBankOutline,
    to: 'processing-payment-list',
    resource: 'payments',
    action: 'list',
  },
  {
    title: 'Payouts',
    icon: mdiBankTransfer,
    to: 'processing-payouts',
    resource: 'payouts',
    action: 'list',
  },
  {
    title: 'Refunds',
    icon: mdiBankTransferIn,
    to: 'processing-refund-list',
    resource: 'refunds',
    action: 'list',
  },
  {
    title: 'Payment Methods',
    icon:  mdiArchiveOutline,
    to: 'payment-methods-list',
    resource: 'payment_methods',
    action: 'list',
  },
  {
    title: 'Payouts Methods',
    icon:  mdiArchiveOutline,
    to: 'payout-methods-list',
    resource: 'payout_methods',
    action: 'list',
  },
]