import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard-general',
    resource: 'dashboard',
    action: 'view',
  },
]
